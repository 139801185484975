import request from '@/utils/request';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';
import router from '@/router';

const fetchSeries = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}papers/series`,
		method: 'get',
	}).then((response) => {
		commit('UPDATE_SERIES', response.data);
		return response;
	});
};

const fetchAgents = ({ commit }) => {
	const query = router.currentRoute.query;
	let url = getQueryUrl(query);

	return request({
		url: `${PRIVATE_API_PREFIX}papers/agents${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		commit('UPDATE_AGENTS', response.data);
		return response;
	});
};

const checkedPaper = ({ dispatch, commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}papers/${payload}/agents`,
		method: 'put',
		data: {},
	}).then((response) => {
		dispatch('totalPapers');
		return response;
	});
};

const fetchPapers = ({ commit }, payload) => {
	const route = router.currentRoute;
	let url = getQueryUrl(route.query);

	if (!payload && !route.params.id) {
		payload = 0;
	} else if (!payload) {
		payload = route.params.id;
	}

	return request({
		url: `${PRIVATE_API_PREFIX}papers/${payload}/papers${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		commit('UPDATE_PAPPER', response.data);
	});
};

const isChecked = () => {
	const route = router.currentRoute;
	let url = getQueryUrl(route.query);

	return request({
		url: `${PRIVATE_API_PREFIX}papers/paper/checked${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		return response;
	});
};

const totalPapers = ({}) => {
	const query = router.currentRoute.query;
	let url = getQueryUrl(query);
	return request({
		url: `${PRIVATE_API_PREFIX}papers/total-papers${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		return response;
	});
};

const fetchTimeBySeries = ({ commit }, payload) => {
	const query = router.currentRoute.query;
	let url = getQueryUrl(query);
	if (payload) {
		return request({
			url: `${PRIVATE_API_PREFIX}papers/${payload}/lottery-times${url}`,
			method: 'get',
			data: {},
		}).then((response) => {
			commit('UPDATE_LOTTERY_TIME', response.data);
			return response;
		});
	} else {
		commit('UPDATE_LOTTERY_TIME', []);
	}
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchSeries,
	fetchAgents,
	fetchPapers,
	checkedPaper,
	isChecked,
	totalPapers,
	fetchTimeBySeries,
};
