import request from '@/utils/request';
import router from '@/router';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';
import notification from 'ant-design-vue/es/notification';

const fetUsers = ({ commit }, payload) => {
	const { query } = router.currentRoute;
	let url = '';
	if (query.page) {
		url = '?page=' + query.page;
	}

	return request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	}).then((result) => {
		if (result.status === 200) {
			commit('UPDATE_USER', result.data);
			commit('UPDATE_PAGINATION', result.meta);
		}
		return result;
	});
};

const fetchSeries = ({ commit }, payload) => {
	commit('UPDATE_SERIES_SPING', true);
	return request({
		url: `${PRIVATE_API_PREFIX}entry-series/series`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			if (response.status === 200) {
				commit('UPDATE_SERIES_SPING', false);
				commit('UPDATE_SERIES', response.data);
			}
			return response;
		})
		.finally(() => {
			commit('UPDATE_SERIES_SPING', false);
		});
};

const fetchShiftBySeries = ({ commit, state }, payload) => {
	commit('UPDATE_SPINNING', true);
	const param = router.currentRoute;
	const url = getQueryUrl(param.query);
	const series_id = router.currentRoute.params;
	const series = state.series;
	if (series.length) {
		const sery = series.filter((obj) => {
			return obj.id == series_id.id;
		});
		if (sery.length) commit('UPDATE_SERIES_NAME', sery[0].name);
	}
	if (series_id.id) {
		return request({
			url: `${PRIVATE_API_PREFIX}entry-series/${series_id.id}/series${url}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				if (response.status === 200) {
					commit('UPDATE_SPINNING', false);
					commit('UPDATE_SHIFT_SERIES', response.data);
				}
				return response;
			})
			.finally(() => {
				commit('UPDATE_SPINNING', false);
			});
	} else {
		commit('UPDATE_SPINNING', false);
	}
};

const fetchSeriesResults = ({ commit, state }, payload) => {
	commit('UPDATE_SPIN', true);
	const param = router.currentRoute;
	const series_id = param.params.id ? param.params.id : payload;
	const url = getQueryUrl(param.query);
	const timeTypeId = param.query.lottery_time;
	const shifts = state.shifts;
	if (shifts.length && timeTypeId) {
		const shift = shifts.filter((obj) => {
			return obj.id == timeTypeId;
		});
		if (shift.length)
			commit('UPDATE_SERIES_SHIFT', shift[0].time_type_name);
	}
	if (series_id) {
		return request({
			url: `${PRIVATE_API_PREFIX}entry-series/${series_id}/results${url}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				if (response.status === 200) {
					commit('UPDATE_SPIN', false);
					commit('UPDATE_SERIES_RESULTS', response.data);
				}
				return response;
			})
			.finally(() => {
				commit('UPDATE_SPIN', false);
			});
	} else {
		commit('UPDATE_SPIN', false);
	}
};

const createEntrySeries = ({ commit, dispatch }, payload) => {
	const { id } = router.currentRoute.params;
	const { lottery_time, dates } = router.currentRoute.query;
	if (!id) {
		notification.warning({
			message: 'Warning',
			description: 'Please select series.',
		});
		return;
	} else if (!lottery_time) {
		notification.warning({
			message: 'Warning',
			description: 'Please select series time type.',
		});
		return;
	} else {
		return request({
			url: `${PRIVATE_API_PREFIX}entry-series`,
			method: 'post',
			data: {
				series_id: id,
				time_type: lottery_time,
				date: dates,
				entry_series: JSON.stringify(payload),
			},
		})
			.then((response) => {
				if (response.status === 200) {
					commit('UPDATE_SERIES_RESULTS', response.data);
					dispatch('fetchSeriesResults');
					dispatch('fetchShiftBySeries');
				}
				return response;
			})
			.catch((errors) => {
				return errors;
			});
	}
};

const scrapLotteryResult = ({ commit, dispatch }, payload) => {
	commit('UPDATE_SPIN', true);
	const { id } = router.currentRoute.params;
	const { lottery_time, dates } = router.currentRoute.query;
	if (!id) {
		notification.warning({
			message: 'Warning',
			description: 'Please select series.',
		});
		return;
	} else if (!lottery_time) {
		notification.warning({
			message: 'Warning',
			description: 'Please select series time type.',
		});
		return;
	} else {
		return request({
			url: `${PRIVATE_API_PREFIX}entry-series/${id}/scrap?lottery_time=${lottery_time}&dates=${dates}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				console.log(response);
				if (response.status === 200 && response.data.length > 0) {
					commit('UPDATE_SERIES_RESULTS', response.data);
				}
				return response;
			})
			.catch((errors) => {
				return errors;
			})
			.finally(() => {
				commit('UPDATE_SPIN', false);
			});
	}
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetUsers,
	fetchSeries,
	fetchShiftBySeries,
	fetchSeriesResults,
	createEntrySeries,
	scrapLotteryResult,
};
