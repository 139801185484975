import request from '@/utils/request';
import router from '@/router';
import { PRIVATE_API_PREFIX } from '@/utils/api_prefix';

const fetchUsers = ({ commit }, payload) => {
	return request({
		url: `${PRIVATE_API_PREFIX}users${url}`,
		method: 'get',
		data: {},
	}).then((reponse) => {
		return reponse;
	});
};

const fetchSeries = ({ commit }) => {
	return request({
		url: `${PRIVATE_API_PREFIX}verify-numbers/series`,
		method: 'get',
		date: {},
	}).then((response) => {
		commit('UPDATE_SERIES', response.data);
		return response;
	});
};

const fetchShifts = ({ commit }, payload) => {
	if (payload == '' || payload == undefined) {
		payload = 0;
	}

	return request({
		url: `${PRIVATE_API_PREFIX}verify-numbers/${payload}/shifts`,
		method: 'get',
		date: {},
	}).then((response) => {
		commit('UPDATE_SHIFTS', response.data);
		return response;
	});
};

const fetchAgents = ({ commit, dispatch }) => {
	const route = router.currentRoute;
	const url = getQueryUrl(route.query);

	return request({
		url: `${PRIVATE_API_PREFIX}verify-numbers${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		commit('UPDATE_AGENTS', response.data);
		return response;
	});
};

const fetchPapers = ({ commit }, payload) => {
	const route = router.currentRoute;

	let agent = router.currentRoute.params.id;

	if (agent === undefined || agent === '') {
		agent = 0;
	}

	if (payload === undefined || payload == '') {
		payload = agent;
	}

	const url = getQueryUrl(route.query);

	return request({
		url: `${PRIVATE_API_PREFIX}verify-numbers/${payload}/papers${url}`,
		method: 'get',
		data: {},
	}).then((response) => {
		commit('UPDATE_PAPERS', response.data);
		commit('UPDATE_TOTAL_PAPERS', response.data.length);

		return response;
	});
};

const fetchBetResult = ({ commit }) => {
	const route = router.currentRoute;
	const url = getQueryUrl(route.query);
	commit('UPDATE_FORM_SPINNING', true);

	return request({
		url: `${PRIVATE_API_PREFIX}verify-numbers/player-bet-results${url}`,
		method: 'get',
		data: {},
	})
		.then((response) => {
			const { data } = response;
			commit('UPDATE_BETRESULT', data);
			commit('UPDATE_FORM_SPINNING', false);
			commit('UPDATE_DATE_FORM', route.query.date);
		})
		.finally(() => {
			commit('UPDATE_FORM_SPINNING', false);
		});
};

const fetchPaperNumber = ({ commit }) => {
	commit('UPDATE_NUMBER_SPIN', true);
	const route = router.currentRoute;
	const url = getQueryUrl(route.query);
	const { id } = route.params;
	if (id) {
		return request({
			url: `${PRIVATE_API_PREFIX}verify-numbers/${id}/lists${url}`,
			method: 'get',
			data: {},
		})
			.then((response) => {
				if (response.status == 200) {
					commit('UPDATE_NUMBER_LIST', response.data);
				}
				commit('UPDATE_NUMBER_SPIN', false);
			})
			.finally(() => {
				commit('UPDATE_NUMBER_SPIN', false);
			});
	} else commit('UPDATE_NUMBER_SPIN', false);
};

const approvedPaper = ({ commit }, payload) => {
	console.log(payload);
	return request({
		url: `${PRIVATE_API_PREFIX}verify-numbers/${payload.id}`,
		method: 'post',
		data: { payload },
	}).then((response) => {
		return response;
	});
};

function getQueryUrl(queries) {
	var url = '';
	var i = 0;
	Object.keys(queries).forEach((key, index) => {
		if (queries[key] != undefined) {
			i += 1;
			if (i == 1) {
				url += `?${key}=${queries[key]}`;
			} else {
				url += `&${key}=${queries[key]}`;
			}
		}
	});
	return url;
}

export default {
	fetchUsers,
	fetchSeries,
	fetchPapers,
	fetchAgents,
	fetchShifts,
	fetchBetResult,
	fetchPaperNumber,
	approvedPaper,
};
